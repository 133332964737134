<template>
    <v-card
        class="mx-auto text-center d-flex flex-column align-center">
        <v-card-text>
            <v-sparkline                      
                :labels="xValue"          
                :value="values"          
                color="primary lighten-1"
                height="100"                
                line-width="2"
                stroke-linecap="round"
                show-labels
                auto-draw
                smooth>                
            </v-sparkline>                 
            <v-divider color="grey" class="mb-1"></v-divider>                            
            <span class="font-weight-regular grey--text text--darken-2">
                User Sessions in Past 24hrs            
            </span>
        </v-card-text>
        
    </v-card>    
</template>

<script>
    export default{
        name:'reports',
        data(){
            return{
                xValue: [
                    '7am','8am','9am','10am','11am',
                    '12pm','1pm','2pm','3pm','4pm',
                    '5pm',
                ],
                values: [
                    0,200,230,500,0,300,100,400,221,
                    0,10
                ]
            }
        }

    }
</script>
