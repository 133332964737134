<template>
    <div>
        <v-row class="fill-height ma-3">
            <v-col cols="7" class="my-auto">
                <reports></reports>
            </v-col>
            <v-col cols="5">
                <v-row class="my-auto mx-auto">        
                    <v-col cols="6" align="center">
                        <v-card width="100%">
                            <v-card-title class="justify-center text-h3 primary--text text--lighten-1 font-weight-light">                                
                                3/5                                
                            </v-card-title>
                            <v-card-text class="d-flex justify-center grey--text text--darken-2">                                                                
                                  Kiosks Online
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-card width="100%">
                            <v-card-title class="justify-center text-h3 primary--text text--lighten-1 font-weight-light">                                
                                3/5                                
                            </v-card-title>
                            <v-card-text class="d-flex justify-center grey--text text--darken-2">                                                                
                                    Users Online
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-card width="100%">
                            <v-card-title class="justify-center text-h3 primary--text text--lighten-1 font-weight-light">                                
                                NA                                
                            </v-card-title>
                            <v-card-text class="d-flex justify-center grey--text text--darken-2">                                                                
                                    Questions Responded
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-card width="100%">
                            <v-card-title class="justify-center text-h3 primary--text text--lighten-1 font-weight-light">                                
                                NA                          
                            </v-card-title>
                            <v-card-text class="d-flex justify-center grey--text text--darken-2">                                                                
                                    Dial-In Sessions
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>
    import reports from '@/components/dashboard/admin/kioskAnalytics/reports.vue'
    export default {
      name: 'adminHome',
      components:{
          reports
      }
    }
</script>